import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbAnchor,
} from '../components/breadcrumb';
import serializeHyperlink from '../utils/serializeHyperlink';

const OrderProcessing = ({ pageContext, data }: any) => {
  const { language } = pageContext;
  const { formatMessage } = useIntl();
  const OrderProcessingQuery = data.allPrismicSimplePage.nodes[0];
  const { data: pageData } = OrderProcessingQuery;

  return (
    <Layout lang={language}>
      <SEO
        title={formatMessage({
          id: 'orderProcessing',
          defaultMessage: 'Order processing',
        })}
      />
      <Breadcrumb className="breadcrumb--workbench">
        <BreadcrumbAnchor
          label={formatMessage({
            id: 'home',
            defaultMessage: 'home',
          })}
          url="/"
        />
        <BreadcrumbDivider />
        <BreadcrumbAnchor
          active
          label={formatMessage({
            id: 'orderProcessing',
            defaultMessage: 'Order processing',
          })}
        />
      </Breadcrumb>
      <Section>
        <RichText
          render={pageData.text.richText}
          serializeHyperlink={serializeHyperlink}
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query OrderProcessingQuery($prismicLang: String) {
    allPrismicSimplePage(
      filter: { lang: { eq: $prismicLang }, tags: { eq: "order-processing" } }
    ) {
      nodes {
        _previewable
        data {
          text {
            richText
          }
        }
        last_publication_date
      }
    }
  }
`;

export default withPrismicPreview(OrderProcessing);
